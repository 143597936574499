.knowledge {
	background: url('../img/bg-knowledge.png') no-repeat;
	background-size: cover;
	padding: 30px 0;
	box-shadow: inset 0 0 21px rgba(0,0,0,.18);
	
	& .knowledge__item {
		@include span(6);
		padding-top: 20px;

		@media screen and (max-width: 768px) {
			@include span(12);
		}
	}

	& .knowledge__fotoWR {
		@include span(5);
	}

	& .knowledge__foto {
		width: 100%;
		
	}

	& .knowledge__preview {
		@include span(7);
		padding-bottom: 50px;
		position: relative;
	}

	& .knowledge__title {
		color: $black;
		padding-bottom: 15px;
	}

	& .knowledge__text {

	}

	& .knowledge__link {
		display: block;
		position: absolute;
		right: 10px;
		bottom: -10px;
		color: $green;
		text-decoration: none;
		border: 1px solid $green;
		padding: 5px 14px 6px;
	}
}