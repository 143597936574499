.footer {
	@include layout(inside);
	background-color: $green;
	box-shadow: 0 0 21px rgba(0,0,0,.5);
	height: 300px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;

	@media screen and (max-width: 767px) {
		height: auto;
	}

	& .footer__col--left {
		// @include container;
		@include span(4);
		padding: 35px 0;

		@media screen and (max-width: 767px) {
			@include span(12);
			padding: 15px;
		}
	}

	& .footer__col--center {
		@include span(6);
		position: relative;
		height: 300px;
		padding: 35px 0;
		background-color: $green3;
		box-shadow: 0 0 21px rgba(0,0,0,.5);

		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	& .footer__col--right {
		@include span(2);
		padding: 35px 0 0;

		@media screen and (max-width: 767px) {
			@include span(12);
			padding: 15px 0;
		}
	}

	.footer__col--wr {
		position: absolute;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		min-height: 100%;
	}

	& .logo {
		@include span(7);
		padding: 0 10px;

		@media screen and (max-width: 767px) {
			@include span(6);

		}


		& .logo__link {
			background-color: $white;
			display: block;
			position: relative;
			height: 100px;
			width: 100%;
			padding: 5px;
		}

		& .logo__img {
			width: 80%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
		}
	}

	& .main-menu {
		@include span(5);

		@media screen and (max-width: 767px) {
			@include span(6);
			padding-left: 20px;
		}

		& .main-menu__title {
			padding-bottom: 10px;
		}

		& .main-menu__list {
			list-style: none;
		}
	}


	& .menu-catalog {
		padding: 0 10px;

		& .menu-catalog__title {
			padding-bottom: 10px;
			display: block;
			width: 100%;
		}

		& .menu-catalog__list {
			list-style: none;
		}

		& .menu-catalog__item {
			@include gallery(6);
		}
	}


	& .contacts {
		padding: 0 10px;

		& .contacts__title {
			padding-bottom: 10px;
		}

		& .phone {
			width: 100%;
			float: none;
			margin-bottom: 15px;

			& .phone__number .fa-phone {
				// border: 1px solid $white;
				// border-radius: 50%;
				padding: 3px;
			}

			& .phone__info {
				// padding-left: 30px;
				text-align: right;
			}

			@media screen and (max-width: 767px) {
				@include span(6);
			}
		}

		& .email__link {
			& .fa-envelope {
				display: inline;
				padding-right: 5px;	
			}

			
		}
	}
}