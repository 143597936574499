html {
	font-size: 100%;
}

body {
	font: 13px/20px "Ubuntu", Arial, sans-serif;
}

h2.title {
	display: inline-block;
	color: $black;
	border-bottom: 2px solid $green;
	font-size: 26px;
	padding-bottom: 7px;
	margin-bottom: 20px;
}

header {

	.infoPicto {
		& .infoPicto__text {
			line-height: 1rem;
		}
	}

	& .phone {
		& .phone__number {
			font-size: 1.5em;
			line-height: 26px;

			& .fa-phone {
			    font-size: 1rem;
			}
		}	

		& .phone__info {
			font-size: 0.9em;
			line-height: 26px;
		}
		
		@media screen and (max-width: 1200px) {
			font-size: 13px;
		}

	}

	& .callback {
		& .callback__btn {
			text-transform: uppercase;
			text-decoration: none;
			text-align: center;
			font-size: 10px;
			line-height: 12px;
			color: $green;
		}
	}

	& .menuCatalog {

		& .menuCatalog__btn {
			line-height: 50px;
			font-size: 1.2rem;
			font-weight: bold;
			text-transform: uppercase;

			& .fa-bars {
				font-size: 2rem;
				line-height: 50px;
			}

			& .fa-chevron-down {
				font-size: 0.8rem;
			}
		}
	}

	.basket {
		& .basket__amount {
			font-weight: bold;
			font-size: 14px;
			text-align: center;
			color: $grey2;
			line-height: 50px;
		}

		& .basket__count {
			color: $white;
			text-align: center;
			line-height: 22px;
		}
	}
	
}