@import 'normalize/_normalize.scss';
@import 'susy/_susy.scss';
@import 'bootstrap/_bootstrap.scss';
@import 'fontawesome/font-awesome.scss';
@import '_variables.scss';
@import 'typography/_index.scss';
@import 'jquery-ui/jquery-ui.min.scss';
@import 'layout/_top.scss';
@import 'layout/_mobile-sidebar.scss';
@import 'layout/_header.scss';
@import 'layout/_basket.scss';
@import 'layout/_mobile-header.scss';
@import 'layout/_menu-catalog.scss';
@import 'glide/_glide.core.scss';
@import 'glide/_glide.theme.scss';
@import 'layout/_catalog-main-page.scss';
@import 'layout/_knowledge.scss';
@import 'layout/_about.scss';
@import 'layout/_catalog.scss';
@import 'layout/_detail.scss';
@import 'flexslider/flexslider.scss';
@import 'magnific-popup/magnific-popup.scss';
@import 'layout/_product-gallery.scss';
@import 'layout/_filters.scss';
@import 'layout/_footer.scss';

*, html, body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// transition: all 0.5s ease;
	outline: none;
}

.container {
	@include container(1200px);


	@media screen and (max-width: 1024px) {
		padding: 0 15px;
	}
}


a {
	outline: none;

	&:active {
		text-decoration: none;
	}

	&:visited {
		text-decoration: none;
	}
}


.page {
	position: relative;
	top: 0;
	left: 0%;
	box-shadow: 0 0 21px rgba(0,0,0,.18);
	transition: left 0.5s ease;
	z-index: 999;
	min-height: 100vh;
	padding-bottom: 300px;

	&.active {
		position: fixed;
		top: 0;
		width: 100%;
		left: 80%;
	}


}


#range {
	// margin-top: 5px;
	background-color: $grey4;
	position: relative;

	@media screen and (max-width: 767px) {
		display: block;
		width: 90%;
		float: none;
		margin: 0 auto 35px;
	}
	

	& .range__min {
		position: absolute;
		left: 0;
		bottom: -23px;
		transform: translateX(-50%);
	}

	& .range__max {
		position: absolute;
		right: 0;
		bottom: -23px;
		transform: translateX(50%);
	}
}


.ui-slider-handle  {
	background-color: $green;
	border-radius: 50%;
	border: 2px solid $white;
	box-shadow: inset 0 0 5px rgba(0,0,0,.5);
	box-shadow:  0 0 5px rgba(0,0,0,.5);
	transition: background-color 0.5s ease;

	&:hover {
		cursor: pointer;
		background-color: $greenLight;	
	}
}

.ui-slider-range {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#48a216+0,5ea038+26,f4c350+78,f77700+100 */
	background: #48a216; /* Old browsers */
	background: -moz-linear-gradient(left,  #48a216 0%, #5ea038 26%, #f4c350 78%, #f77700 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #48a216 0%,#5ea038 26%,#f4c350 78%,#f77700 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #48a216 0%,#5ea038 26%,#f4c350 78%,#f77700 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48a216', endColorstr='#f77700',GradientType=1 ); /* IE6-9 */

}

