$susy: (
	flow: ltr,
	math: fluid,
	output: float,
	gutter-position: inside,
	container: 1200px,
	container-position: center,
	columns: 12,
	gutters: 0.10,
	column-width: false,
	global-box-sizing: border-box,
	// debug: (
	// 	image: show,
	// 	color: rgba(#66f, .25),
	// 	output: background,
	// 	toggle: top right
	// )
);



/*
* Colors
***************************************/
$green: #48a216;
$green2: #3cc051;
$green3: #338406;
$greenLight: #58c61b;
$grey: #333333;
$grey2: #969ba5;
$grey3: #85878b;
$grey4: #e0e7ec;
$grey5: #ecf0f1;
$white: #ffffff;
$black: #1d2029;
$black2: #222222;
$orange: #ffa019;
$red: #cc0000;

