.mobileHeader {
	background-color: $white;
	display: none;
	padding: 15px 0;
	border-top: 5px solid $green;	
	box-shadow: 0 0 21px rgba(0,0,0,.18);

	&.fixed {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 999;
	}

	.mobileHeader__hamburger {
		@include span(1);
		text-align: center;
	}

	.mobileHeader__logo {
		@include span(5);

		& .logo__image {
			height: 30px;
		}
	}

	.mobileHeader__wr {
		@include span(6);
		text-align: center;
	}

	.mobileHeader__phone {
		width: 30px;
		float: right;
		margin-right: 15px;


		& .phone__icon {
		}

		& .phone__link {
			display: inline-block;
			background-color: $green;
			width: 30px;
			height: 30px;
			border-radius: 50%;
		}

	}

	.mobileHeader__basket {
		width: 130px;
		float: right;
		margin-right: 0;
		position: relative;


		& .basket__icon {
			background: url('../img/icon-cart-mobile.png');
			display: block;
			width: 30px;
			height: 31px;
			float: left;
		}

		& .basket__amount {
			float: right;

		}

		& .basket__count {
			background-color: $red;
			display: block;
			position: absolute;
			top: -9px;
			left: 17px;
			width: 20px;
			height: 20px;
			border-radius: 50%;

		}

	}


	@media screen and (max-width: 1023px) {
		display: block;
	}
}