.menuCatalog {
	// @include span(2 of 13);
	width: 280px;
	position: absolute;
	z-index: 499;
	background-color: $white;
	height: 50px;
	// transition: height 0.5s ease;
	overflow: hidden;

	&:hover {
		height: auto;
		box-shadow: 0 0 21px rgba(0,0,0,.18);
		overflow: visible;
		z-index: 999;
	}

	& .menuCatalog__btn {
		text-decoration: none;
		color: $black;
		display: block;
		height: 50px;
		

		& .fa-bars {
			color: $green;
			display: inline-block;
			position: relative;
			top: 4px;
			padding-right: 10px;
			padding-left: 20px;
			transition: all 0.5s ease;
		}

		& .fa-chevron-down {
			display: inline-block;
			position: relative;
			bottom: 3px;
			padding-left: 33px;
			color: $orange;
		}

		&:hover .fa-bars {
			color: $greenLight;
		}
	}

	& .menuCatalog__dropdown {
		display: block;
		position: relative;
		// background-color: $white;
		width: 100%;
		padding: 15px 0;
		z-index: 500;

		& ul {
			margin: 0;
			padding: 0;
		}

		& .menuCatalog__item {
			display: block;
			list-style: none;
			line-height: 32px;
			width: 100%;
			height: 32px;

			&:hover {
				background-color: $grey5;
				border-left: 3px solid $green;
			}

			&:hover .dropdown__submenu {
				display: block;
			}
		}

		& .menuCatalog__link {
			text-decoration: none;
			display: inline-block;
			height: 32px;
			width: 100%;
			color: $grey;
			padding: 0 15px;
			

			// &:hover {
			// 	background-color: $grey5;
			// 	border-left: 3px solid $green;
			// }
		}

		&.active {
			display: block;
		}
	}

	& .dropdown__submenu {
		background-color: $white;
		// box-shadow: 0 0 21px rgba(0,0,0,.18);
		// padding: 20px;
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		// padding-left: 300px;
		width: 980px;
		z-index: -1;

		& .submenu__inner {
			background-color: $white;
			display: block;
			position: absolute;
			left: 280px;
			right: 0;
			top: 0;
			bottom: auto;
			min-height: 100%;
			padding: 20px;
			
			box-shadow: 0 0 21px rgba(0,0,0,.18);
			z-index: 999;
		}

		& .submenu__item {
			display: block;
			height: 70px;
			// line-height: 70px;
			margin-bottom: 5px;
			margin-right: 10px;
			width: 48%;
			float: left;

			&:after {
				content: '';
				display: block;
				clear: both;
				height: 0;
				visibility: hidden;
			}
		}

		& .submenu__link {
			display: table-row;
			width: 100%;
			text-decoration: none;

			&:after {
				content: '';
				display: block;
				clear: both;
				height: 0;
				visibility: hidden;
			}

			&:hover .submenu__fotoWR {
				// border: 1px solid $grey4;
			}

			&:hover .submenu__title {
				// background-color: $grey4;
				text-decoration: underline;
			}
		}

		& .submenu__fotoWR {
			display: table-cell;
			position: relative;
			width: 70px;
			height: 70px;
			border: 1px solid $white;
			// padding: 3px;
			transition: all 0.5s ease;
		}

		& .submenu__foto {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			width: 65px;
			max-height: 70px;
		}

		& .submenu__title {
			display: table-cell;
			padding: 5px 5px 0 15px;
			vertical-align: middle;
			margin-left: 80px;
			line-height: 20px;
			color: $grey;
			transition: all 0.5s ease;
		}
	}

}