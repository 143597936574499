.mobileHeader {

	& .mobileHeader__hamburger {
		line-height: 30px;
		font-size: 24px;
	}

	& .mobileHeader__phone {
		line-height: 30px;
		font-size: 1.3rem;
	}

	& .phone__link {
		color: $white;
		text-decoration: none;
	}

	& .basket__amount {
		line-height: 30px;
		color: $grey3;
		font-weight: bold;
	}

	& .basket__count {
		font-size: 0.7rem;
		color: $white;
		line-height: 20px;
	}
}