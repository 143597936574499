.mobileSidebar {
	background-color: $white;
	display: none;
	width: 80%;
	min-height: 100vh;

	&.active {
		display: block;

	}

	& .search {
		display: block;
		margin: 0;
		width: 100%;
		clear: both;
		overflow: hidden;
		
		&:after {
			content: '';
			display: table;
			clear: both;
			visibility: hidden;
		}
	}


	& .menu {

		padding-top: 50px;
	
		h2 {
			background-color: $white;
			display: inline-block;
			position: relative;
			width: 100%;
			padding: 10px 10px;
			box-shadow: 0 0 21px rgba(0,0,0,.18);
			z-index: 995;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -5px;
				left: 20px;
				background-color: transparent;
				border-top: 5px solid $white;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
			}
		}

	}

	& .menu__catalog {
		background-color: $grey5;
		position: relative;
		list-style: none;
		margin: 0;
		padding-top: 5px;
		// box-shadow: inset 0 0 21px rgba(0,0,0,.18);
		

		& .catalog__section {
			display: block;
			position: relative;
			width: 100%;
			height: 30px;
			padding: 0 10px;
			border-bottom: 1px solid $grey3;

			&:last-child {
				border-bottom: none;
			}

			&.back {
				background-color: $white;
				border-bottom: none;
				background-color: $white;
				display: inline-block;
				position: relative;
				width: 100%;
				padding: 10px 10px;
				box-shadow: 0 0 21px rgba(0,0,0,.18);
				z-index: 995;
				height: auto;
			}

		}

		& .catalog__sectionLink {
			display: inline-block;
			width: 100%;

			& .fa-angle-right {
				position: absolute;
				right: 10px;
				transform: translateY(50%);
			}

		}

		& .catalog__subSection {
			background-color: $grey5;
			display: block;
			position: fixed;
			top: 0;
			left: 80%;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100vh;
			// min-height: 100vh;
			transition: all 0.8s ease;
			z-index: 996;
			box-shadow: 0 0 21px rgba(0,0,0,.18);
			padding-top: 5px;

			&.active {
				// display: block;
				left: 0%;
				z-index: 999;
			}

			& .catalog__element {
				height: 30px;

			}

		}
	}

	& .menu__site {
		

		& .site__item {
			display: inline-block;
			width: 100%;
			height: 30px;
			padding: 0 10px;
			border-bottom: 1px solid $grey3;

			&:last-child {
				border-bottom: none;
			}
		}
	}
}