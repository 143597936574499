.glide__arrows {
  position: absolute;
}
.glide--horizontal .glide__arrows {
  top: 50%;
  width: 92%;
  margin-left: 4%;
}
.glide--vertical .glide__arrows {
  left: 50%;
  height: 88%;
  margin-top: 6%;
}
.glide__arrow {
  position: absolute;
  display: block;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  font: 11px Arial, sans-serif;
  // padding: 9px 12px;
  width: 41px;
  height: 41px;
  line-height: 36px;
  font-size: 22px;
  background-color:rgba( 0, 0, 0, 0.25 );
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  -webkit-transition: border 300ms ease-in-out;
          transition: border 300ms ease-in-out;


}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide--horizontal .glide__arrow {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.glide--horizontal .glide__arrow.prev {
  left: 0;
}
.glide--horizontal .glide__arrow.next {
  right: 0;
}
.glide--vertical .glide__arrow {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.glide--vertical .glide__arrow.prev {
  top: 0;
}
.glide--vertical .glide__arrow.next {
  bottom: 0;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  list-style: none;
}
.glide--horizontal .glide__bullets {
  bottom: 8%;
  left: 0;
  width: 100%;
  height: 12px;
  text-align: center;
}
.glide--vertical .glide__bullets {
  top: 50%;
  right: 8%;
  width: 12px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.glide__bullets > * {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.5);
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid transparent;
  -webkit-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
}
.glide--horizontal .glide__bullets > * {
  margin: 0 5px;
}
.glide--vertical .glide__bullets > * {
  vertical-align: middle;
}
.glide__bullets > *.active {
  background-color: white;
}
.glide__bullets > *:focus {
  outline: none;
}
.glide__bullets > *:hover,
.glide__bullets > *:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide--slider .glide__arrow {
  opacity: 1;
  -webkit-transition: opacity 150ms ease;
          transition: opacity 150ms ease;
}
.glide--slider .glide__arrow.disabled {
  opacity: 0.33;
}


.glide__slide {
  background-size: cover;
  height: 400px;
  box-shadow: inset 0 0 21px rgba(0,0,0,.18);

  @media screen and (max-width: 767px) {
    height: auto;
  }
  
}

.glide__slideInfo {
  @include span(6);
  height: 400px;
  position: relative;

  & .slideInfo__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 80%;
    height: 200px;
  }

  & h2 {
    padding-bottom: 35px;
  }
  
  & p.pb1 {padding-bottom: 10px;}
  & p.pb2 {padding-bottom: 15px;}
  & p.pb3 {padding-bottom: 20px;}

  @media screen and (max-width: 767px) {
    @include full;
    float: none;
  }
}

.glide__slideFoto {
  @include span(6);
  position: relative;
  height: 400px;

  & .slideFoto__foto {
    position: absolute;
    max-height: 350px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;


    @media screen and (max-width: 1023px) {
      width: 100%;
    }

    
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    float: none;
    height: 300px;
    

    & .slideFoto__foto {
      max-height: none;
      width: 80%;
    }
  }
}