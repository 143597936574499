.catalog {
	& .catalog-menu {


		& .catalog-menu__link.active {
			color: $white;

			&:hover {
				color: $white;
			}



			&:before {
				color: $white;
			}
		}			


		& .catalog-menu__link {
			color: $grey;
			text-decoration: none;

			&:hover {
				color: $green;
			}

			&:before {
				color: $grey;
				font-size: 0.6rem;
			}

		}

		& .catalog-submenu__link {
			text-decoration: none;
			color: $grey;

			&:hover {
				color: $green;
			}
		}
	}

	.content__title {
		line-height: 34px;
		display: block;
		width: 100%;
	}

	.breadcrumb {
		& .breadcrumb__link {
			text-decoration: none;
			color: $grey;

			&:hover {
				color: $green;
			}
		}

		& .breadcrumb__separator {
			color: $green;
			font-weight: bold;
		}
	}

	& .catalog-items {
		& .catalog-items__link {
			text-decoration: none;
			color: $green;
		}

		& .catalog-items__name {

			@media screen and (max-width: 767px) {
				font-weight: bold;
				font-size: 0.9rem;
			}
		}

		& .catalog-items__order {
			color: $white;
			text-decoration: none;
			text-transform: uppercase;
		}

		& .catalog-items__price {
			font-weight: bold;
			font-size: 1.1rem;

			@media screen and (max-width: 767px) {
				font-size: 1.5rem;
			}
		}

		& .catalog-items__inStock {
			color: $white;
			text-transform: uppercase;
			font-size: 0.7rem;
		}
	}
}

.catalog__btnMoreItems {
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: $green;
	font-weight: bold;
	line-height: 24px;

	& .fa-angle-right {
		font-weight: bold;
		font-size: 1.1rem;
	}
}

.catalog-mark {
	color: $grey;

	& .catalog-mark__link {
		text-decoration: none;
		color: $grey;

		&:hover {
			color: $green;
			text-decoration: underline;
		}
	}
}

.catalog-filter__btnSort {
	font-size: 0.7rem;
	// text-transform: uppercase;
	text-align: right;

	@media screen and (max-width: 1024px) {
		font-size: 0.6rem;
	}

	@media screen and (max-width: 767px) {
		font-size: 0.8rem;
		text-align: center;
	}

	a {
		text-decoration: none;
		display: inline-block;
		margin-left: 5px;
		background-color: $white;
		border: 1px solid $green;
		color: $green;
		padding: 3px;
	}
}
.catalog.main-page {

	& .catalog__title {
		text-align: center;
		font-size: 0.9rem;
		font-weight: bold;
		color: $black;
	}

	@media screen and (max-width: 768px) {
		& .catalog__title {
			font-size: 0.9rem;
		}
	}

	@media screen and (max-width: 425px) {
		& .catalog__title {
			font-size: 0.9rem;
		}
	}
}