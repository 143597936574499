.filters {
	border-bottom: 1px solid $grey4;

	& .filters__title {
		position: relative;
		margin: 0;
		padding: 7px 0 7px 5px;
		background-color: $white;
		// border-bottom: 1px solid $grey4;
		border-top: 1px solid $grey4;
		border-right: 1px solid $grey4;
		border-left: 1px solid $grey4;
		cursor: pointer;


		&:before {
			// content: '';
			@extend .fa;
			@extend .fa-angle-right;
			display: inline-block;
			position: absolute;
			right: 7px;
			z-index: 500;
			
			
		}
	}

	& .filters__title.active {
		// background-color: $green;

		&:before {
			@extend .fa-angle-down;
		}
	}

	.filters__content {
		background-color: $white;
		display: none;
		padding: 10px;
		border-left: 1px solid $grey4;
		border-right: 1px solid $grey4;
		overflow: hidden;

		&.active {
			display: block;
		}
	}

	& .filters__wr-input {
		@include span(6);
	}
}

.range-filters {
	// padding-top: 100px;
	// padding-bottom: 30px;
	// margin-top: 30px;

	& .range-filters__info {
		margin-bottom: 15px;
		overflow: hidden;
	}

	& .range-filters__line {
		background-color: $grey4;
		padding: 0 20px;
	}

	& .range-filters__wr-input {
		@include span(6);
		overflow: hidden;
	}

	& .range-filtes__label {
		@include span(3);
		display: block;
		text-align: right;
		padding: 3px;
	}

	& .range-filters__min, & .range-filters__max {
		@include span(9);
		display: block;
		padding: 3px;
		border: 1px solid $green;
		color: $black;
	}


}