.catalog {
	background: $white;
	padding: 15px 0 30px;

	& .sidebar {
		@include span(3);
		padding-right: 20px;

		@media screen and (max-width: 1023px) {
			display: none;
		}
	}

	& .content {
		@include span(9);

		@media screen and (max-width: 1023px) {
			@include span(12);
		}
	}

	& .catalog-menu {
	margin-bottom: 30px;
		

		& .catalog-menu__list {
			list-style: none;

		}

		& .catalog-menu__item {
			
			border-bottom: 1px solid $grey4;
		}

		& .catalog-menu__item.active {
			

			
		}

		& .catalog-menu__link {
			display: inline-block;
			position: relative;
			width: 100%;
			padding: 7px 0 7px 5px;



			&:before {
				@extend .fa;
				@extend .fa-chevron-right;
				position: absolute;
				right: 5px;
				top: 50%;
				transform: translateY(-50%);
			}

			&.active {
				background-color: $green;

				&:before {
					@extend .fa-chevron-down;
				}

				& ~ .catalog-submenu {
					display: block;
				}
			}

			
		}

		& .catalog-submenu {
			background-color: $grey5;
			display: none;
			// position: relative;
			color: $black;
			list-style: none;

			& .catalog-submenu__link {
				display: inline-block;
				padding: 7px 0 7px 5px;
				border-bottom: 1px solid $grey4;
			}
		}

	}

	& .breadcrumb {
		display: inline-block;
		padding: 0 0 0 10px;
		background-color: #fff;

		& .breadcrumb__separator {
			padding: 0 10px;
		}
	}

	& .catalog-items__wr {
		@include layout(12 (4em 1em) fluid after);
		@include break();
		overflow: hidden;
		padding: 0 0 30px;
		clear: both;
	}

	& .catalog-items {
		@include gallery(3);
		margin-bottom: 1em;
		border: 1px solid $grey4;
		padding: 7px;

		@media screen and (max-width: 768px) {
			@include gallery(4);
		}

		@media screen and (max-width: 767px) {
			@include gallery(12);
			position: relative;
			float: none;
			
		}

		&:hover {
			border: 1px solid $green;
		}


		& .catalog-items__wrFoto {
			position: relative;
			height: 200px;
			width: 100%;

			@media screen and (max-width: 767px) {
				height: 300px;
			}
		}

		& .catalog-items__foto {
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;

			@media screen and (max-width: 767px) {
				width: 60%;
			}
		}

		& .catalog-items__name {
			display: inline-block;
			height: 60px;

			@media screen and (max-width: 767px) {
				display: block;
				height: auto;
			}
		}

		& .catalog-items__price {
			float: left;
			padding: 7px 0;
		}

		& .catalog-items__order {
			background-color: $green;
			float: right;
			padding: 7px 10px;
			border: 1px solid $green;
		}

		& .catalog-items__inStock {
			display: inline-block;
			margin-bottom: 7px;
			background-color: $greenLight;
			padding: 4px 7px;

			@media screen and (max-width: 767px) {
				position: absolute;
				top: 10px;
				right: 10px;
			}

			&:after {
				content: '';
				display: table;
				clear: both;
			}
		}

		& .catalog-items__wrBottom {
			overflow: hidden;

			@media screen and (max-width: 767px) {
				margin-top: 15px;
			}
		}
	}
}

.catalog__btnMoreItems {
	@include span(4);
	margin: 0 auto;
	display: block;
	border: 1px solid $green;
	float: none;
	padding: 10px;
	position: relative;

	@media screen and (max-width: 767px) {
		@include span(12);
	}

	& .fa-angle-right {
		position: absolute;
		right: 20px;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
	}
}


.content {
	& .content__title {
		margin: 0;
		// margin-bottom: 15px;
		padding-bottom: 10px;
	}


	& .content__container {
		@include layout(inside);
		@include full();
		padding: 20px 0;

		& p {
			margin-bottom: 20px;
		}
	}
}

.catalog-mark {
	display: block;	
	border: 1px solid $grey4;
	padding: 10px;
}


.catalog-filter {
	padding: 30px 0;
	overflow: hidden;

	& .catalog-filter__price-input {
		@include span(4);

		@media screen and (max-width: 767px) {
			@include span(12);
			margin-bottom: 20px;
			float: none;
			overflow: hidden;
		}
	}

	& .price-input__input {
		width: 70px;
		height: 25px;
		border: 1px solid $green;
		padding: 3px;

		@media screen and (max-width: 767px) {
			@include span(4);
		}
	}

	& .price-input__label {

		@media screen and (max-width: 767px) {
			@include span(2);
			text-align: center;
		}
	}

	& .catalog-filter__range {
		@include span(3);
		
		@media screen and (max-width: 767px) {
			@include span(12);
		}
	}

	& .catalog-filter__btnSort {
		float: none;
		@include layout(inside);

		& a {

			@media screen and (max-width: 767px) {
				@include span(4);
				display: block;
				margin: 0;
				border-right: none;

				&:last-child {
					border-right: 1px solid $green;
				}
			}

		}
	}
}