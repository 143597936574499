.mobileSidebar {
	& .menu {
		& h2 {
			font-size: 1.1rem;
			color: $grey;
		}
	}

	& .menu__catalog {
		& .catalog__sectionLink {
			line-height: 30px;
			text-decoration: none;
			color: $grey;

			& .fa-angle-right {
				font-weight: bold;
				font-size: 1rem;
			}
		}

	}

	& .menu__site {


		& .site__item {
			line-height: 30px;
		}

		& .site__link {
			text-decoration: none;
			color: $grey;
		}
	}

	& .catalog__section {

		&.back {
			font-size: 1.1rem;
			color: $grey;
			font-weight: bold;
		}
	}
}
