.top {
	background-color: $green;
	width: 100%;
	height: 25px;
}

header {
	background-color: $white;
	padding-top: 15px;
	padding-bottom: 5px;
	border-bottom: 1px solid $grey4;
	box-shadow: 0 0 21px rgba(0,0,0,.18);
	position: relative;
	z-index: 999;

	&.fixed {
		position: fixed;
		top: 0;
		width: 100%;
		border-top: 5px solid $green;
		z-index: 9999;
	}

	@media screen and (max-width: 1023px) {
		display: none;
	}
}

.logo {
	@include span(3);

	& .logo__img {
		
	}
}

.infoPicto {
	@include span(2);

	& .infoPicto__ico {
		float: left;
		margin-right: 10px;
	}

	.infoPicto__text {
		color: $grey;
	}
}

.phone {
	@include span(3);
	padding-right: 30px;

	& .phone__number {
		text-align: right;
		color: $grey2;

		& .black {
			color: $black;
			font-weight: bold;
		}

		& .fa-phone {
			// display: inline-block;
			color: $white;
			background-color: $green;
			border-radius: 50%;
		    margin: 0 5px;
		    padding: 5px 6px;
		    vertical-align: top;
		    width: 25px;
		    height: 25px;
		}
	}

	& .phone__info {
		text-align: right;
		color: $grey2;
	}

	& p {margin: 0;}
}

.callback {
	@include span(2);

	& .callback__btn {
		display: block;
		padding: 5px 14px 6px;
		border: 1px solid $green;
	}
}





.search {
	@include span(4 of 12);
	margin-left: span(3);
	background-color: $grey4;
	padding: 5px;
	position: relative;
	z-index: 995;

	& .search__input {
		width: 80%;
		height: 38px;
		border: none;
		display: block;
		float: left;
		padding-left: 30px;
		outline: none;

		&::focus {
			border: none;
		}
	}

	& .search__btn {
		background-color: $green;
		display: block;
		float: right;
		width: 19%;
		height: 38px;
		color: $white;
		border: none;
		cursor: pointer;
		transition: all 0.5s ease;
		text-transform: uppercase;

		&:hover {
			background-color: $greenLight;
		}
	}

	& .fa-search {
		color: $grey4;
		display: block;
		position: absolute;
		font-size: 22px;
		line-height: 38px;
	}
}

.basket {
	@include span(2);
	position: relative;

	& .basket__wrapper {
		// position: absolute;
		// top: -17px;
		// left: 0;
		// right: 0;
		// bottom: auto;
		// margin: auto;
		// width: 110px;
		// height: 60px;
	}

	& .basket__link {
		background: url('../img/icon-cart.png') no-repeat;
		float: left;
		display: block;
		position: relative;
		width: 35px;
		height: 36px;
		margin: 10px auto 0;
		// background-size: cover;

		// &:hover {
		// 	background-position: 0 -47px;
		// }
	}

	& .basket__icon {
		width: 45px;
		height: 46px;
	}

	& .basket__amount {

	}

	& .basket__count {
		position: absolute;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background-color: $red;
		top: 6px;
		left: 27px;
	}
}