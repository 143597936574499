.product__gallery {
	@include span(7);

	& .product__wr-main-foto {
		@include span(10);
		display: block;
		position: relative;
		height: 370px;
		border: 1px solid $grey4;

		&:hover {
			border: 1px solid $green;			
		}

		& .product__main-foto {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			padding: 20px;
			width: auto;
			height: auto;
			max-height: 340px;
			max-width: span(11);
		}
	}

	.product__wr-list-foto {
		@include span(2);
		height: 370px;

		.product__item-foto {
			list-style-type: none;
			margin-bottom: 5px;
		}

		.product__link-foto {
			display: block;
			position: relative;
			width: 100%;
			height: 60px;
			border: 1px solid $grey4;

			&:hover {
				border: 1px solid $green;				
			}
		}

		& .product__list-foto {
			overflow: hidden;
			height: 320px;
		}

		.product__foto {
			display: block;
			position: absolute;
			padding: 5px;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			width: 100%;
			max-height: 50px;
		}

		& .product__next, & .product__prev {
			border-left: 37px solid transparent;
			border-right: 37px solid transparent;
			width: 100%;
			
		}

		& .product__next {
			border-top: 20px solid $grey4;
			
			&:hover {
				border-top: 20px solid $green;
				cursor: pointer;
			}
		}

		& .product__prev {
			border-bottom: 20px solid $grey4;
			margin-bottom: 5px;

			&:hover {
				border-bottom: 20px solid $green;
				cursor: pointer;
			}
		}
	}
}