.filters {
	& .filters__title {
		color: $black;
		font-size: 0.9rem;
		font-weight: bold;

		&.active {
			color: $green;
		}

		&:hover {
			color: $green;
		}

		&:before {
			font-weight: bold;
		}
	}
}