.catalog.main-page {
	padding: 30px 0;

	& .catalog__section {
		@include gallery(3);
		border: 1px solid $white;
		height: 280px;
		transition: all 0.5s ease;

		&:hover {
			// border: 1px solid $fGrey3;
			box-shadow: 0 0 21px rgba(0,0,0,.18);

			& .catalog__title {
				color: $grey3;
			}	
		}
	}

	& .catalog__link {
		text-decoration: none;
	}

	& .catalog__iconWR {
		position: relative;
		width: 100%;
		height: 230px;
	}

	& .catalog__icon {
		width: 80%;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		max-height: 230px;
	}

	& .catalog__title {

	}

	@media screen and (max-width: 768px) {
		& .catalog__section {
			@include gallery(4);
		}
	}

	@media screen and (max-width: 425px) {
		& .catalog__section {
			@include gallery(6);
			// float: none;
			height: 200px;
		}

		& .catalog__iconWR {
			height: 150px;
		}
		
	}
}