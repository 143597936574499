.basket {
	position: relative;

	&:hover {
		box-shadow: 0 0 21px rgba(0,0,0,.18);
		
		& .basket__content {
			display: block;
		}
	}

	& .basket__content {
		display: none;
		position: absolute;
		right: 0;
		width: 500px;
		background-color: #fff;
		box-shadow: 0 0 21px rgba(0,0,0,.18);
		padding: 10px;
	}

	& .basket__table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;

		
		& td {
			padding: 5px;
		}


		& .basket-table__title {
			text-align: left;
			border-bottom: 1px solid #48a216;
			font-weight: bold;
			color: #48a216;
			padding-bottom: 10px;
		}

		& .basket-table__fotoWR {
			position: relative;
			display: block;
			width: 50px;
			height: 50px;
		}

		& .basket-table__foto {
			vertical-align: middle;

			& img {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
				max-height: 50px;
				max-width: 50px;
			}
		}

		& .basket-table__name {

			& a {
				color: $grey;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		& .basket-table__price {
			vertical-align: middle;
			text-align: center;
			white-space: nowrap;
			// font-weight: bold;
			font-size: 16px;
		}

		& .basket-table__quant {

			& input {
				width: 30px;
				height: 35px;
				text-align: center;
			}
		}

		& .basket-table__delete {
			cursor: pointer;

			& i {
				color: #48a216;;
			}
		}
	}

	& .btn-order {
	    background-color: #48a216;
	    color: #fff;
	    border-radius: 4px;
	    height: 50px;
	    line-height: 50px;
		text-align: center;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 700;
		display: block;
		width: 50%;
		margin: 0 auto;

		&:hover {
			background-color: #3c8214;
    		text-decoration: none;
		}
	}

}