.detail__head {
	position: relative;
	overflow: hidden;

	&-left {
		@include span(6);
		float: left;
		position: relative;
	}

	&-right {
		@include span(6);
		float: right;
		position: relative;
	}

	@media screen and (max-width: 767px) {
		padding-bottom: 90px;

		&-left {
			@include span(12);
		}

		&-right {
			@include span(12);
			margin-top: 20px;
		}

		& .preview-btnwr {
			@include span(12);
		}
	}
}

.wr-thmb {
	width: 80%;
	margin: 0 auto;
}

.thmb__item {
	border: 1px solid #ddd;
	// width: 77px !important;
	height: 77px;
	display: block;
}

.thmb__item:hover {
	border: 1px solid #00cc00;
}

.thmb__item.flex-active-slide {
	border: 1px solid #00cc00;
}

.thmb__link {
	position: relative;
	display: block;
	// width: 100%;
	height: 77px;
	// width: 77px !important;
}

.thmb__img {
	max-width: 90%;
    max-height: 90%;
    width: auto !important;
    height: auto !important;
    display: inline-block !important;
    vertical-align: middle;
    zoom: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.wr-thmb {
	position: relative;
	& .custom-navigation {
		width: 15px;
		position: absolute;
		z-index: 100;

		&.left {left: -30px;top: 0;}
		&.right {right: -30px;top: 0;}


		& .flex-next {line-height: 77px;font-size: 50px;outline: none;text-decoration: none;float: right;display: block;}
		& .flex-prev {line-height: 77px;font-size: 50px;outline: none;text-decoration: none;float: left;display: block;}
		& .flex-next:hover, & .flex-prev:hover {color: $green;}
	}
}

.flexslider {margin-bottom: 0 !important;}

.wr-foto-prod {
	border: 1px solid #ddd;
	position: relative;

	&:hover .navigation {display: block;}

	& .navigation {
		position: absolute;
		line-height: 300px;
		display: none;
		z-index: 100;

		&.left {top: 0;left: -5px;}
		&.right {top: 0;right: -5px;}

		& .flex-next, & .flex-prev {outline: none;
			font-size: 40px;
			width: 40px;
			height: 40px;
			display: inline-block;
			// background-color: rgba(0,0,0,0.2);
			text-align: center;
			border-radius: 50%;
			text-decoration: none;
			color:$green;
		}
	}
}

.foto-prod__item {
	display: block;
	width: 100%;
	height: 300px;
}

.foto-prod__link {
	position: relative;
	display: block;
	width: 100%;
	height: 300px;
}

.foto-prod__img {
	max-width: 90%;
    max-height: 90%;
    width: auto !important;
    height: auto !important;
    display: inline-block !important;
    vertical-align: middle;
    zoom: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;	
}

.preview-info {
	& p {
		margin-bottom: 5px;
		padding: 5px;
		text-align: right;
		display: block;
		position: relative;

		& span {font-weight: bold; position: absolute; left: 5px;}
	}

	& p:nth-child(even) {background-color: $grey4;}
	& p:nth-child(odd) {background-color: #f4f4f4;}
}


.preview-price {
	margin-top: 20px;
	& .preview-price__label {
		border-bottom: 1px solid $grey4;
	}

	&__old_price, &__new_price {
		@include span(6);
		text-align: center;
		padding-top: 15px;
		font-size: 28px;
	}

	&__old_price {color: $grey3; text-decoration: line-through;}
	&__new_price {color: $green;}
}


.preview-btnwr {
	@include span(6);
	position: absolute;
	bottom: 18px;
	right: 0;

	& .btn-order {
		@include span(6);
		border: 1px solid $white;
		background-color: $green;
		display: block;
		height: 50px;
		color: $white;
		line-height: 50px;
		text-align: center;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: bold;
		// box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
		border-radius: 4px;

		&:hover {
			background-color: #3c8214;
			text-decoration: none;
		}
	}
	& .btn-question {
		@include span(6);
		border: 1px solid $white;
		background-color: $green;
		display: block;
		height: 50px;
		color: $white;
		line-height: 50px;
		text-align: center;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: bold;
		// box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
		border-radius: 4px;

		&:hover {
			background-color: #3c8214;
			text-decoration: none;
		}
		
	}
}

.detail__content {
	margin-top: 25px;

	& .row {
		border: 1px solid $grey4;
		margin-bottom: 15px;
		padding: 10px;
		margin-right: 0;
		margin-left: 0;
	}

	& .ui-state-default.ui-state-active a {
		background-color: $green;
		color: $white;
	}

	& .ui-state-default a {
		background-color: $grey4;
		outline: none;
		color: $grey;

		&:hover {
			background-color: $green;
			color: $white;		
		}
	}

	& #tabs-characteristics {
		& table {
			width: 100%;
		}

		& table tr td {
			padding: 5px;
			border: 1px solid $grey4;
		}
	}

	& #tabs-video {
		padding: 15px 0;
		& iframe {
			display: block;
			margin: 0 auto 20px;
		}
	}
}