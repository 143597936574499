.footer {
	font-size: 13px;
	$lh: 1.5rem;

	& .main-menu {
		& .main-menu__title {
			color: $white;
			font-size: 1.1rem;
		}

		& .main-menu__link {
			color: $white;
			text-decoration: underline;
			line-height: $lh;

			&:hover {
				text-decoration: none;
			}
		}
	}

	& .menu-catalog {
		
		& .menu-catalog__title {
			color: $white;
			font-size: 1.1rem;
		}

		& .menu-catalog__link {
			color: $white;
			text-decoration: underline;
			line-height: $lh;

			&:hover {
				text-decoration: none;
			}	
		}
	}

	& .contacts {

		& .contacts__title {
			color: $white;
			font-size: 1.1rem;
		}

		& .phone {

			& .phone__number {
				text-align: left;
				color: $white;
				line-height: $lh;
				font-size: 0.8rem;

				& .fa-phone {
					font-size: 1rem;
					padding: 0;
					display: inline;
				}
			}

			& .phone__info {
				text-align: left;
				color: $white;
			}
		}

		& .email {
			

			& .email__link {
				color: $white;	
			}
		}
	}
}